export const tournaments = [
    {
        name: 'JTCC Wheelchair Championships',
        date: 'Aug. 29 - Sept. 1',
        location: 'College Park, MD',
        director: 'Gabby Hesse',
        level: 'USTA Level 2',
        month: ['August', 'September'],
        going: true,
        complete: true,
    },
    {
        name: 'The Wheelchair Championships at Kennworth',
        date: 'Oct. 10 - 13',
        location: 'Acworth, GA',
        director: 'Tony Oswald',
        level: 'USTA Level 2',
        month: ['October'],
        going: true,
        complete: false,
    },
    {
        name: 'ITA Qualifiers',
        date: 'Nov. 1 - 3',
        location: 'Tuscaloosa, AL',
        director: 'Tyler Mckay',
        level: 'ITA Event',
        month: ['November'],
        going: true,
        complete: false,
    },
    {
        name: 'Wolverine Open',
        date: 'Nov. 14 - 17',
        location: 'Ann Arbor, MI',
        director: 'Chris Kelley',
        level: 'ITA Event',
        month: ['November'],
        going: true,
        complete: false,
    },
    {
        name: 'Piedmont Area Tennis Association Wheelchair Open',
        date: 'Dec. 13 - 15',
        location: 'Charlottesville, VA',
        director: 'Catherine DeSouza',
        level: 'USTA Level 4',
        month: ['December'],
        going: true,
        complete: false,
    }
];
